import { useEffect, useState } from "react";
import { useAdFormContext } from "../../../contexts/AdFormContext";
import axios from "axios";
import { serverAPI } from "../../../utils/envConfig";
import moment from "moment";
import he from "he";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const rotateArray = function (nums, k) {
  for (let i = 0; i < k; i++) {
    nums.unshift(nums.pop());
  }
  return nums;
};

export default function General() {
  // Authentication and Authorization
  const token = JSON.parse(localStorage.getItem("authuser")).access_token;
  const myRole = JSON.parse(localStorage.getItem("authuser")).role;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const { adData, setAdData } = useAdFormContext();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(false);
  const [contact, setContact] = useState([]);
  const [stockno, setStockno] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [wheel, setWheel] = useState("");
  const [cylinder, setCylinder] = useState("");
  const [spec, setSpec] = useState("");
  const [price, setPrice] = useState(0);
  const [emi, setEMI] = useState("");
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [noOffer, setNoOffer] = useState(true);
  const [carTrim, setCarTrim] = useState("");
  const [mayOffer, setMayOffer] = useState("");
  const [vat, setVat] = useState("");

  //   const mayOffer=
  // `Dream it. Drive it.

  // Get the ride of your life this month! Dreams happen!⭐

  // Limited Time offer:
  //    - 1 Year Warranty
  //    - 1 Free Service
  //    - Free Window Tinting
  //    - Free Salik

  // Visit our Al Quoz Showroom in Dubai today. Open every day from 9.30 am to 10.00 pm!

  //  Location: https://goo.gl/maps/XrGnafTmhQQuRqMV9

  // The offer is valid from 1st to 31st August 2022!
  // *Ts&Cs apply.
  // **Applicable on select cars`
  let intHashinsta = `#${make
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .toUpperCase()}${model
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .toUpperCase()} #${make
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .toUpperCase()}UAE #${make
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .toUpperCase()}Dubai #${make
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .toUpperCase()} #${make
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .toUpperCase()}CarDealers #Luxurycarsdubai #albacars #cardealerUAE #carsforsale #luxurycars #carswithoutlimits #newcar #carshopping #cars #dealership #carscene #autosales #carshow #usedcarsforsale #carsofinsta #carsdaily #carspot #cardealership #auto #usedcars #carlifestyle #car #automotive #carsofinstagram`;
  //let intHashlnkd = `#${make.replace(/\s/g, '').toLowerCase()}${model.replace(/\s/g, '').toLowerCase()} #${make.replace(/\s/g, '').toLowerCase()}UAE #albacars #luxurycars #usedcarsshowroom`
  let intHashtwitter = `#${make.replace(/\s/g, "").replace(/-/g, "")}${model
    .replace(/\s/g, "")
    .replace(/-/g, "")} #${make.replace(/\s/g, "").replace(/-/g, "")}UAE #${make
    .replace(/\s/g, "")
    .replace(
      /-/g,
      ""
    )}Dubai #albacars #usedcars #luxurycars #dubaicars #offers #deals`;
  const validateStockNo = async (num) => {
    try {
      const ads = await axios.get(`${serverAPI}/adGen`, { headers });
      if (ads.data.length) {
        let copyads = ads.data.filter((ad) => ad.carstockno === num);
        if (copyads.length === 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (err) {
      // console.log(err)
    }
  };
  const validate = async () => {
    setBtnLoading(true);
    setAdData({ ...adData, contactteams: contact });
    let choice = await validateStockNo(adData.carstockno);
    if (adData.carstockno === "") {
      toast.error("Please enter Car Stock Number");
      setError(true);
    } else if (choice) {
      toast.error("Car Stock Number Exists");
      setError(true);
    } else if (adData.carname === "") {
      toast.error("Please enter make and model");
      setError(true);
    } else if (adData.caryear === "") {
      toast.error("Please enter year");
      setError(true);
    } else if (adData.carprice === "") {
      toast.error("Please enter price");
      setError(true);
    } else if (adData.carodometer === "") {
      toast.error("Please enter odometer");
      setError(true);
    } else if (adData.carcylinder === "") {
      toast.error("Please enter cylinder");
      setError(true);
    } else if (adData.carwheel === "") {
      toast.error("Please enter wheel size");
      setError(true);
    } else if (adData.carspec === "") {
      toast.error("Please enter spec");
      setError(true);
    } else if (adData.description === "") {
      toast.error("Please enter description");
      setError(true);
    } else {
      toast.success("Please click next");
      setError(false);
    }
    setBtnLoading(false);
    return !error;
  };

  useEffect(() => {
    // Get Sales Team members
    const getContact = async () => {
      let arrSales = [];
      let prevArr = [];
      try {
        const lastEntry = await axios.get(`${serverAPI}/adGen`, { headers });
        const res = await axios
          .get(`${serverAPI}/salesTeam`, { headers })
          .then((members) => {
            const salesTeam = members.data.filter((st) => st.status === true);
            if (lastEntry.data.length) {
              let pos = lastEntry.data.length;
              rotateArray(salesTeam, pos).map((sl, index) =>
                arrSales.push({
                  orderno: index + 1,
                  id: sl.id,
                  name: sl.name,
                  phone: sl.phone,
                  whatsupdub: sl.whatsupdub,
                  whatsupfb: sl.whatsupfb,
                })
              );
            } else {
              salesTeam.map((item, index) =>
                arrSales.push({
                  orderno: index + 1,
                  id: item.id,
                  name: item.name,
                  phone: item.phone,
                  whatsupdub: item.whatsupdub,
                  whatsupfb: item.whatsupfb,
                  email: item.email,
                })
              );
            }
            setContact(arrSales);
            setLoading(false);
          })
          .catch((err, res) => {
            // console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    };
    getContact().catch(console.error);

    // Get makes
    const getMakes = async () => {
      try {
        const res = await axios
          .get(`${serverAPI}/cars/makes`, { headers })
          .then((makes) => {
            setMakes(makes.data);
          })
          .catch((err, res) => {
            //  console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    };
    getMakes().catch(console.error);
  }, []);

  // Set Contacts
  useEffect(() => {
    if (Array.isArray(contact) && contact.length) {
      setAdData({ ...adData, contactteams: contact });
    }
  }, [contact]);

  // Get models
  useEffect(() => {
    const getModels = async () => {
      try {
        const res = await axios
          .get(`${serverAPI}/cars/models?make=${make}`, { headers })
          .then((result) => {
            setModels(result.data);
          })
          .catch((err, res) => {
            //  console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    };
    getModels().catch(console.error);
  }, [make]);

  // Get dedcription and options
  useEffect(() => {
    const getDesc = async () => {
      try {
        const carData = {
          make,
          model,
          year,
        };
        setAdData({ ...adData, caryear: year });
        const res = await axios
          .post(`${serverAPI}/cars/sqlCarDesc`, carData, { headers })
          .then((result) => {
            let description = he
              .decode(result.data.vehicle_description)
              .replace(/<\/?[^>]+(>|$)/g, "");
            setAdData({ ...adData, description: description, caryear: year });
          })
          .catch((err, res) => {
            //  console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    };
    getDesc().catch(console.error);
  }, [year]);

  // Get offer
  useEffect(() => {
    const getOffer = async () => {
      try {
        const res = await axios
          .get(`${serverAPI}/monthlyOfferLatest`, { headers })
          .then((result) => {
            const today = moment().format("MMM");
            const entry = result.data;
            let monthAd = entry.filter(
              (s) => moment(entry[0].createdate).format("MMM") === today
            );
            // comment out this line of code
            setAdData({ ...adData, offers: result.data[0].offer });
            if (monthAd.length) {
              let arr = monthAd[0].offers;
              // Uncomment this line of code
              //setAdData({...adData,offers:arr})
            }
          })
          .catch((err, res) => {
            //  console.log(err)
          });
      } catch (err) {
        // console.log(err)
      }
    };
    getOffer().catch(console.error);
  }, []);

  // Make offer empty
  useEffect(() => {
    if (!noOffer) {
      setAdData({ ...adData, offers: "" });
    } else {
      const getOffer = async () => {
        try {
          const res = await axios
            .get(`${serverAPI}/monthlyOfferLatest`, { headers })
            .then((result) => {
              const today = moment().format("MMM");
              const entry = result.data;
              let monthAd = entry.filter(
                (s) => moment(entry[0].createdate).format("MMM") === today
              );
              // comment out this line of code
              setAdData({ ...adData, offers: result.data[0].offer });
              if (monthAd.length) {
                let arr = monthAd[monthAd.length - 1].offers;
                // Uncomment this line of code
                // setAdData({...adData,offers:arr})
              }
            })
            .catch((err, res) => {
              //  console.log(err)
            });
        } catch (err) {
          // console.log(err)
        }
      };
      getOffer().catch(console.error);
    }
  }, [noOffer]);

  // Set Car Make, Model
  useEffect(() => {
    const carnameapp = make + " " + model + " " + carTrim;
    setAdData({
      ...adData,
      carwebsitetumblr: model,
      carname: carnameapp,
      carhashinsta: intHashinsta,
      carhashtwitter: intHashtwitter,
    });
  }, [make, model]);

  const handleStockno = (e) => {
    setStockno(e.target.value);
  };
  const handleMake = (e) => {
    setMake(e.target.value);
  };

  const handleModel = (e) => {
    setModel(e.target.value);
  };

  // Set Car Year
  const handleYear = (e) => {
    setYear(e.target.value);
    setPrice(0);
    setAdData({ ...adData, carprice: 0, caremi: 0 });
  };
  const handleVat= (e) => {
    setVat(e.target.value);
    setAdData({ ...adData, addexpenses:e.target.value});
  };

  // Set Car Specification
  const handleSpec = (e) => {
    setSpec(e.target.value);
  };

  useEffect(async () => {
    setAdData({ ...adData, carstockno: stockno });
    let choice = await validateStockNo(stockno);
    if (choice) {
      toast.error("Car stock number exists");
    }
  }, [stockno]);

  useEffect(() => {
    setAdData({ ...adData, carspec: spec });
  }, [spec]);

  // Set Car Wheel Size
  const handleWheel = (e) => {
    setWheel(e.target.value);
  };

  useEffect(() => {
    setAdData({ ...adData, carwheel: wheel });
  }, [wheel]);

  // Set Car Cylinder
  const handleCylinder = (e) => {
    setCylinder(e.target.value);
  };
  // Set Car Trim
  const handleTrim = (e) => {
    setCarTrim(e.target.value);
  };
  useEffect(() => {
    const carnameapp = make + " " + model + " " + carTrim;
    setAdData({ ...adData, carname: carnameapp, carhashlnkd: carTrim });
  }, [carTrim]);

  useEffect(() => {
    setAdData({ ...adData, carcylinder: cylinder });
  }, [cylinder]);

  // Set Car Price
  const handlePrice = (e) => {
    setPrice(e.target.value);
  };

  // Calculate EMI based on Price and Year of Car
  useEffect(() => {
    setAdData({ ...adData, carprice: price });
    let priceCalc = parseInt(price);
    let yearCalc = parseInt(adData["caryear"]);
    let emiCalc = "";
    let emiyear = 0;
    if(yearCalc === 2014) {
      emiyear = 1;
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * emiyear) / (emiyear*12));
      emiCalc = `${emiCalc}`;
    }
    else if (yearCalc === 2015) {
   
      emiyear = 2;
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * emiyear) / (emiyear*12));
      emiCalc = `${emiCalc}`;
    }
    // else if (yearCalc === 2015) {
    //   emiCalc = Math.round((priceCalc + priceCalc * 0.035 * 3) / 36);
    //   emiyear = 3;
    //   emiCalc = `${emiCalc}`;
    // }
    else if (yearCalc === 2016) {
     
      emiyear = 3;
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * emiyear) / (emiyear*12));
      emiCalc = `${emiCalc}`;
    }
    else if (yearCalc === 2017) {
     
      emiyear = 4;
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * emiyear) / (emiyear*12));
      emiCalc = `${emiCalc}`;
    }
    else {
      emiCalc = Math.round((priceCalc + priceCalc * 0.035 * 5) / (5*12));
      emiCalc = `${emiCalc}`;
    }
    setEMI(emiCalc);
  }, [price]);

  useEffect(() => {
    setAdData({ ...adData, caremi: emi });
  }, [emi]);

  // Set Other Input Variables
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdData({ ...adData, [name]: value });
  };

  return (
    <>
      {loading && (
        <div className="w-full pt-5 pr-5 pl-5 pb-2 bg-gray-200 lg:w-full">
          <div className="container mx-auto">
            <div className="flex justify-center items-center">
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <div className="space-y-5">
          <div className="bg-white shadow px-4 py-5 mb-2 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Contact Details:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    {Array.isArray(contact) &&
                      contact.length &&
                      contact.map((item, index) => (
                        <div>
                          <label
                            htmlFor="contactteam"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {item.name}
                          </label>
                          <label
                            htmlFor="contactteam"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {item.phone}
                          </label>
                          {/* <label htmlFor="contactteam" className="block text-sm font-medium text-gray-700">
                      {item.whatsupdub}
                      </label>  */}
                          <label
                            htmlFor="contactteam"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {item.whatsupfb}
                          </label>
                          <br />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <p className="mt-0 mb-2 text-sm text-gray-500">
              (* Mandatory fields)
            </p>
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Car Information:{" "}
                </h3>
              </div>

              <div className="mt-5 md:mt-0 md:col-span-4">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6 lg:col-span-6">
                    <label
                      htmlFor="stockno"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Car Stock Number (Easy cars)
                    </label>
                    <input
                      type="number"
                      name="carstockno"
                      value={adData["carstockno"] || ""}
                      onChange={handleStockno}
                      id="stockno"
                      placeholder="Car Stock number"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Make
                    </label>
                    <select
                      id="make"
                      name="make"
                      autoComplete="car-make"
                      onChange={handleMake}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      {makes.map((x, y) => (
                        <option key={y}>{x}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Model
                    </label>
                    <select
                      id="model"
                      name="model"
                      autoComplete="car-model"
                      onChange={handleModel}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      {models.map((x, y) => (
                        <option key={y}>{x}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="carhashlnkd"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Trim
                      </label>
                      <input
                        type="text"
                        name="carhashlnkd"
                        autoComplete="off"
                        onChange={handleTrim}
                        id="carhashlnkd"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="carname"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Car Name
                    </label>
                    <input
                      type="text"
                      name="carname"
                      value={adData["carname"] || ""}
                      onChange={handleChange}
                      id="carname"
                      autoComplete="carname"
                      placeholder="Porsche Cayenne GTS"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="carname"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title (Extra)
                    </label>
                    <select
                      type="text"
                      name="cartitle"
                      value={adData["cartitle"] || ""}
                      onChange={handleChange}
                      id="cartitle"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="Perfect Condition!">
                        Perfect Condition!
                      </option>
                      <option value="Excellent Condition!">
                        Excellent Condition!
                      </option>
                      <option value="Amazing Condition!">
                        Amazing Condition!
                      </option>
                      <option value="Fantastic Condition!">
                        Fantastic Condition!
                      </option>
                      <option value="Immaculate Condition!">
                        Immaculate Condition!
                      </option>
                      <option value="Spectacular Condition!">
                        Spectacular Condition!
                      </option>
                      <option value="Impeccable Condition!">
                        Impeccable Condition!
                      </option>
                      <option value="Magnificient Condition!">
                        Magnificient Condition!
                      </option>
                      <option value="Extraordinary Condition!">
                        Extraordinary Condition!
                      </option>
                      <option value="Pristine Condition!">
                        Pristine Condition!
                      </option>
                      <option value="Agency Warranty!">Agency Warranty!</option>
                      <option value="Full Agency History!">
                        Full Agency History!
                      </option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="cartitle"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title (Extra)
                    </label>
                    <input
                      type="text"
                      name="cartitle"
                      value={adData["cartitle"] || ""}
                      onChange={handleChange}
                      id="cartitle"
                      autoComplete="cartitle"
                      placeholder="Immaculate Condition"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="year"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Year
                    </label>
                    <select
                      id="year"
                      name="year"
                      autoComplete="year"
                      onChange={handleYear}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="caryear"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Car Year
                    </label>
                    <input
                      readOnly
                      type="number"
                      name="caryear"
                      value={adData["caryear"] || ""}
                      onChange={handleChange}
                      id="caryear"
                      autoComplete="caryear"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                    <label
                      htmlFor="carprice"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Price (AED)
                    </label>
                    <input
                      type="number"
                      name="carprice"
                      value={adData["carprice"] || ""}
                      onChange={handlePrice}
                      id="carprice"
                      autoComplete="carprice"
                      placeholder="179900"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>


                  <div className="col-span-6 sm:col-span-4 lg:col-span-2">
                    <label
                      htmlFor="caremi"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * EMI (AED)
                    </label>
                    <input
                      readOnly
                      type="text"
                      name="caremi"
                      value={adData["caremi"] || emi}
                      onChange={handleChange}
                      id="caremi"
                      placeholder="3523"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                    <label
                      htmlFor="carprice"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * VAT
                    </label>
                    <select
                      id="vat"
                      name="vat"
                      onChange={handleVat}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="Inclusive of VAT">Inclusive</option>
                      <option value="Exclusive of VAT">Exclusive</option>

                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="carodometer"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Odometer (Kms)
                    </label>
                    <input
                      type="number"
                      name="carodometer"
                      value={adData["carodometer"] || ""}
                      onChange={handleChange}
                      id="carodometer"
                      autoComplete="carodometer"
                      placeholder="43700"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="carwarranty"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Warranty
                    </label>
                    <input
                      type="text"
                      name="carwarranty"
                      value={adData["carwarranty"] || ""}
                      onChange={handleChange}
                      id="carwarranty"
                      autoComplete="carwarranty"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="carservice"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Service
                    </label>
                    <input
                      type="text"
                      name="carservice"
                      value={adData["carservice"] || ""}
                      onChange={handleChange}
                      id="carservice"
                      autoComplete="carservice"
                      placeholder="Service"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="cylinder"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cylinders
                    </label>
                    <select
                      id="cylinder"
                      name="cylinder"
                      autoComplete="cylinder"
                      onChange={handleCylinder}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                      <option value="12">12</option>
                      <option value="16">16</option>
                      <option value="Unknown">Unknown</option>
                      <option value="None - Electric">None - Electric</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="carcylinder"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Car Cylinders
                    </label>
                    <input
                      type="text"
                      name="carcylinder"
                      value={adData["carcylinder"] || ""}
                      onChange={handleChange}
                      id="carcylinder"
                      autoComplete="carcylinder"
                      placeholder="6"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="wheel"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Wheel Size
                    </label>
                    <select
                      id="wheel"
                      name="wheel"
                      autoComplete="wheel"
                      onChange={handleWheel}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value='14"'>14"</option>
                      <option value='15"'>15"</option>
                      <option value='16"'>16"</option>
                      <option value='17"'>17"</option>
                      <option value='18"'>18"</option>
                      <option value='19"'>19"</option>
                      <option value='20"'>20"</option>
                      <option value='21"'>21"</option>
                      <option value='22"'>22"</option>
                      <option value='23"'>23"</option>
                      <option value='24"'>24"</option>
                      <option value='25"'>25"</option>
                      <option value='26"'>26"</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="carwheel"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Car Wheel Size
                    </label>
                    <input
                      type="text"
                      name="carwheel"
                      value={adData["carwheel"] || ""}
                      onChange={handleChange}
                      id="carwheel"
                      autoComplete="carwheel"
                      placeholder='21"'
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="spec"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Specification
                    </label>
                    <select
                      id="spec"
                      name="spec"
                      autoComplete="spec"
                      onChange={handleSpec}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select</option>
                      <option value="GCC">GCC</option>
                      <option value="EUROPEAN">EUROPEAN</option>
                      <option value="OTHERS">Others</option>
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <label
                      htmlFor="carspec"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Car Specification
                    </label>
                    <input
                      type="text"
                      name="carspec"
                      value={adData["carspec"] || ""}
                      onChange={handleChange}
                      id="carspec"
                      autoComplete="carspec"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Offers:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-1">
                <label
                  htmlFor="carspec"
                  className="block text-sm font-medium text-gray-700"
                >
                  Yes/No
                </label>
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input appearance-none h-5 w-5 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      checked={noOffer}
                      onChange={(e) => {
                        setNoOffer((t) => !t);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="offers"
                      name="offers"
                      value={adData["offers"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="March Madness is here! It's time to make your move. Buy your dream car today!
                      Take advantage of our Limited Time Offer:
                      - One Year Warranty
                      - One Free Service
                      Offer is Valid until 31st March 2022! *Ts&amp;Cs apply. **Offer Valid on Selected Cars only"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  * Description:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      value={adData["description"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="The Porsche Cayenne is one of the most appealing midsize luxury crossovers thanks to its sophisticated
                      engineering, impeccable construction, immense features list and surprisingly useful cabin.
                      The 3.6L V6 engine is truly in a class of its own. This sophisticated engineered machine strikes a
                      harmonious balance of speed and acceleration.
                      In excellent condition, the elegant White color exterior combined with the luxurious Brown leather
                      inside, will leave your guests begging you for a ride home."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  * Options:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="options"
                      name="options"
                      value={adData["options"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="-Bose Sound System
                      -Rear Camera
                      -Sunroof
                      -Electric/Memory Seats
                      -Cruise Control
                      -Climate Control
                      -IsoFix Seats
                      -Sports Modes
                      -Electric Tailgate
                      -Navigation System
                      -Keyless Entry/Start"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Cash Buyers:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="cashbuyers"
                      name="cashbuyers"
                      value={adData["cashbuyers"] || ""}
                      onChange={handleChange}
                      rows={4}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Please provide:
                    1- Emirates ID
                    2- Driving License"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Finance Buyers:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="financebuyers"
                      name="financebuyers"
                      value={adData["financebuyers"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Required Bank finance documents are as follows:
                    —
                    
                    Employed:
                    1- Salary Certificate
                    2- 3 month bank statement (stamped)
                    3- Passport &amp; Visa copies)
                    4- Emirates ID copy
                    ( Note: Please contact us if you have received only one or no salaries and work for a listed company)
                    —
                    Self Employed:
                    1- Trade License
                    2- MOA
                    3- Passport copies of all partners
                    4- Passport and visa copies of applicant
                    5- Emirates ID
                    6- 3 month personal bank statement
                    7- 3 month company bank statement
                    —
                    Companies:
                    1- Trade License
                    2- MOA
                    3- Passport copies of all partners
                    4- 3 month company bank statement"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Booking Options:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="booking"
                      name="booking"
                      value={adData["booking"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="To start the application we require a deposit of AED 5,000 through one of the following methods :
                    -
                    1- Credit/Debit card - to be refunded in cash once vehicle is registered.
                    —
                    2- Cash - to be refunded as Cash once vehicle is registered.
                    —
                    3- Cheque - Not to be cashed - to be returned once vehicle is registered.
                    —
                    Applicable Terms &amp; Conditions to be presented upon the time of booking."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Additional Information:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="addexpenses"
                      name="addexpenses"
                      value={adData["addexpenses"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    //   placeholder="If its consignment
                    // 1- Registration: AED 1,100
                    // 2- Insurance: TBC
                    // If not
                    // 1- Registration: AED 945
                    // 2- Insurance: TBC
                    // 3- VAT 5%
                    // Or
                    // If not
                    // 1- Registration: AED 945
                    // 2- Insurance: TBC"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-2 py-4 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Sell your car:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div>
                  <div className="mt-1">
                    <textarea
                      id="sellyourcar"
                      name="sellyourcar"
                      value={adData["sellyourcar"] || ""}
                      onChange={handleChange}
                      rows={6}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="Simply fill out the form by clicking the link below :
                    —
                    https://albacars.ae/sell-used-car-dubai/
                    —
                    We pay cash and pay for Bank early settlements."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-6 md:gap-4">
              <div className="md:col-span-1">
                <h3 className="text-base font-medium leading-6 text-gray-900">
                  Contact Information:{" "}
                </h3>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-4">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="carwebsite"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Website Link
                    </label>
                    <input
                      type="text"
                      name="carwebsite"
                      value={adData["carwebsite"] || ""}
                      onChange={handleChange}
                      id="carwebsite"
                      autoComplete="carwebsite"
                      placeholder="www.albacars.ae"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="carwebsitepin"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Car Link
                    </label>
                    <input
                      type="text"
                      name="carwebsitepin"
                      value={adData["carwebsitepin"] || ""}
                      onChange={handleChange}
                      id="carwebsitepin"
                      autoComplete="carwebsitepin"
                      placeholder="https://www.albacars.ae/buy-used-cars/vehicle/3740-Mercedes-Benz-GLE-400-4Matic-AMG-Kit
                          Empty"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  {/* <div className="col-span-6 sm:col-span-6">
                      <label htmlFor="carwebsitetumblr" className="block text-sm font-medium text-gray-700">
                          Car Link - Tumblr
                      </label>
                      <input
                          type="text"
                          name="carwebsitetumblr"
                          value={adData["carwebsitetumblr"] || ""}
                          onChange={handleChange}
                          id="carwebsitetumblr"
                          autoComplete="carwebsitetumblr"
                          placeholder='<a href="car link">car brand model</a>
                          <a href="used car page link">Buy used luxury cars in Dubai</a>'
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                  </div> */}

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="carlocationgoogle"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Location - Googlemaps
                    </label>
                    <input
                      type="text"
                      name="carlocationgoogle"
                      value={adData["carlocationgoogle"] || ""}
                      onChange={handleChange}
                      id="carlocationgoogle"
                      autoComplete="carlocationgoogle"
                      placeholder="https://goo.gl/maps/XrGnafTmhQQuRqMV9"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="carshowroom"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Showroom Timings
                    </label>
                    <input
                      type="text"
                      name="carshowroom"
                      value={adData["carshowroom"] || ""}
                      onChange={handleChange}
                      id="carshowroom"
                      autoComplete="carshowroom"
                      placeholder="Sunday to Saturday (10:00 AM - 10:00 PM)"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="carhashinsta"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Hash Tags - Instagram
                    </label>
                    <textarea
                      type="text"
                      name="carhashinsta"
                      value={adData["carhashinsta"] || ""}
                      onChange={handleChange}
                      id="carhashinsta"
                      autoComplete="carhashinsta"
                      placeholder="#porschecayenne #porscheUAE #albacars #luxurycars #usedcarsshowroom"
                      rows={3}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="carhashtwitter"
                      className="block text-sm font-medium text-gray-700"
                    >
                      * Hash Tags - Twitter
                    </label>
                    <input
                      type="text"
                      name="carhashtwitter"
                      value={adData["carhashtwitter"] || ""}
                      onChange={handleChange}
                      id="carhashtwitter"
                      autoComplete="carhashtwitter"
                      placeholder="#porschecayenne #porscheUAE #albacars #luxurycars #usedcarsshowroom"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <button
                      onClick={() => validate()}
                      id="kt_sign_in_submit"
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-right px-4 py-2 border text-sm font-medium rounded-md bg-[#F25757] hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 opacity-1"
                    >
                      <span className="text-white font-sm">
                        {btnLoading && (
                          <svg
                            role="status"
                            className="inline mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        )}
                        Validate
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
